import { Critica } from '../protocols';

/**
 *
 * @param type 0 - Capa, 1 - Itens, 2 - Financeiro, 3 - Guia, 4 - ICMS, 5 - Frete, 6 - Fornecedor
 * @param title
 * @param message
 * @returns
 */

export function createCritica(
  type: number,
  title: string,
  message: string,
): Critica {
  return {
    type,
    title,
    message,
    show: true,
  };
}
