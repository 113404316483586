import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';

export const schemaFormFinanceiro = yup
  .object({
    finalizadora: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    dta_vencimento: yup.string().required(),
    val_financeiro: yup
      .string()
      .required()
      .test((val) => {
        const valueToFloat = transformAsCurrency(val || 0);
        return valueToFloat > 0;
      }),
    condicao: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    num_condicao: yup.number().required(),
  })
  .required();
